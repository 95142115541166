.main {
  /* Stretch as needed so that footer is always at the bottom */
  flex-grow: 1;
}

.main__content {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: var(--s1) var(--s2);

  border-top: 1px solid var(--grey);
  padding-top: var(--s0);

  /* Match the larger spacing in the footer */
  padding-bottom: var(--s2);
}

.main__content[data-layout=full-width] {
  grid-template-columns: 1fr;
}

@media (max-width: 60rem) {
  .main__content {
    /* Get rid of 350px width so overflow doesn't occur on smaller screens */
    grid-template-columns: 1fr;
  }
}

/*******************************************************************************
 *  Head
 *******************************************************************************/

.main__head {
  /* Stretch across the full grid */
  grid-column: 1 / -1;

  display: flex;
  flex-flow: column nowrap;
  gap: var(--s0);
}

.main__head > .content-box ul {
  display: flex;
  flex-flow: row wrap;
  gap: var(--s0);
  white-space: nowrap;
}

.main__supertitle {
  font-weight: bold;
}

.main__title {
  font-size: var(--text-4xl);
  font-weight: bold;
  line-height: 1.2;
}

.main__subtitle {
  font-size: var(--text-2xl);
}

/*******************************************************************************
 *  Body
 *******************************************************************************/

.main__body > * + * {
  margin-top: var(--s2);
}

[data-layout="sidebar"] .main__body {
  grid-column: 1 / 2;
}

[data-layout="full-width"] .main__body {
  grid-column: 1 / -1;
}

@media (max-width: 60rem) {
  [data-layout="sidebar"] .main__body,
  [data-layout="full-width"] .main__body {
    grid-column: 1 / -1;
  }
}

.main__prose {
  /* Make sure floats don't overflow */
  display: flow-root;
}

.main__prose a {
  text-decoration: revert;
}

.main__prose > * + * {
  margin-top: var(--s0);
}

.main__prose .float-left {
  max-width: 15rem;
}

.main__prose .float-left:first-child + * {
  margin-top: 0;
}

@media (max-width: 35rem) {
  .main__prose .float-left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--s0);
  }
}

/*******************************************************************************
 *  Tail
 *******************************************************************************/

.main__tail > * + * {
  margin-top: var(--s2);
}

[data-layout="sidebar"] .main__tail {
  grid-column: 2 / -1;
}

[data-layout="full-width"] .main__tail {
  grid-column: 1 / -1;
}

@media (max-width: 60rem) {
  [data-layout="sidebar"] .main__tail,
  [data-layout="full-width"] .main__tail {
    grid-column: 1 / -1;
  }
}
