@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Lato'), local('Lato-Regular'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/lato/Lato-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Lato-Italic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Lato-Italic'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/lato/Lato-Italic.woff2) format('woff2');
}

@font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Lato-Bold'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/lato/Lato-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('PT Serif'), local('PT Serif-Regular'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/ptserif/PTSerif-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'PT Serif Italic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('PT Serif-Italic'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/ptserif/PTSerif-Italic.woff2) format('woff2');
}

@font-face {
    font-family: 'PT Serif Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('PT Serif-Bold'), url(https://wehco.media.clients.ellingtoncms.com/static-4/webfont/ptserif/PTSerif-Bold.woff2) format('woff2');
}


@font-face {
    font-family: "Lato-Fallback-Arial";
    src: local("Arial");
    ascent-override: 101.3181%;
    descent-override: 21.865%;
    size-adjust: 97.4159%;
}

@font-face {
    font-family: "Lato-Bold-Fallback-Arial-Bold";
    src: local("Arial");
    font-weight: 700;


@font-face {
    font-family: "Lato Fallback: Roboto";
    src: local('Roboto');
    /*ascent-override: 101.4302%;
    descent-override: 21.8892%;
    size-adjust: 97.3083%;*/
}

@font-face {
    font-family: "PT-Serif-Fallback-Times-New-Roman";
    src: local('Times New Roman'), local('Times');
    /*ascent-override: 93.5808%;
    descent-override: 25.7595%;
    size-adjust: 111.0271%;*/
}
@font-face {
    font-family: "PT Serif Fallback: Georgia";
    src: local('Georgia');
    /*ascent-override: 102.7217%;
    descent-override: 28.2757%;
    size-adjust: 101.1471%;*/
}


.font-loaded {
    letter-spacing: 0!important;
    word-spacing: 0!important;
}
