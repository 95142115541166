/*
 *  Footer
 */

.footer {
  background-color: var(--light-grey);
}

.footer__content {
  display: flex;
  flex-flow: column nowrap;

  /* Give content some extra space */
  margin-top: var(--s2);
  gap: var(--s2);
  margin-bottom: var(--s2);
}

.footer__logo {
  /* Scales down the logo to fit in the remaining header space on smaller screens */
  max-width: 100%;

  /* The SVG must have a height set, so here is a sensible default that can be overridden per site */
  height: 2rem;

  /* Preflight gives img "object-fit: cover" by default, but we want the logo completely visible at all times */
  object-fit: contain;
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.footer__legal {
  font-size: 0.75rem;
}

.footer__legal > * + * {
  margin-top: 1em;
}
