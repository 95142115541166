/*
 *  Ad
 */

.ad {
  /* make sure it is always centered */
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  grid-column: 1 / -1;
}

.ad__label {
  text-transform: uppercase;
  font-size: var(--text-xs);
  line-height: 2em;
  color: var(--dark-grey);
}

.ad__window {
  background-color: var(--grey);
}

.ad--mobile {
  display: none;
}

@media (max-width: 1040px) {
  .ad--desktop {
    display: none;
  }

  .ad--mobile {
    display: flex;
  }

  .ad__window[data-ad="div-gpt-ad-320x50"] {
    display: block!important;
  }
}

.ad__window[data-ad="div-gpt-ad-320x50"] {
  min-width: 320px;
  min-height: 50px;
}

.ad__window[data-ad="div-gpt-ad-300x250"] {
  min-width: 300px;
  min-height: 250px;
  display: block!important;
}

.ad__window[data-ad="div-gpt-ad-300x600"] {
  min-width: 300px;
  min-height: 600px;
  display: block!important;
}

.ad__window[data-ad='div-gpt-ad-flex'] {
  height: 250px;

}
