.breaking-bar {
  position: relative;
  background-color: var(--red);

  font-style: italic;
  color: var(--white);
}

.breaking-bar__content {
  margin-bottom: var(--s-1);
  margin-top: var(--s-1);
  text-align: center;
}

.breaking-bar__content a::after {
  /* stretch the clicking space over the area of the card */
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}