.archive > * + * {
  margin-top: var(--s2);
}

.archive__date-picker {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--s0);
}

.archive__section > * + * {
  margin-top: var(--s0);
}

.archive__list li:last-of-type .card {
  border-bottom: 1px solid var(--grey);
  padding-bottom: var(--s0);
}



/*******************************************************************************
 *  Navigation
 *******************************************************************************/

.archive__nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 
    "left center right";
}

.archive__nav__left {
  grid-area: left;
  justify-self: start;
}

.archive__nav__center {
  grid-area: center;
  justify-self: center;
}

.archive__nav__right {
  grid-area: right;
  justify-self: end;
}