/*
 *  Global header
 */

.header {
  background: var(--black);
  color: var(--white);
}

.header__content {
  /* Grid allows header__logo__link to be centered regardless of content in header__nav and header__user-controls */
  display: grid;
  grid-template-columns: 1fr auto 1fr; /* header__nav | header__logo__link | header__user-controls */
  gap: var(--s1);

  margin-top: var(--s0);
  margin-bottom: var(--s0);
}

/*******************************************************************************
 *  Navigation Menu
 *******************************************************************************/

.header__nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header__nav a[href="#main"] {
  display: inline-block;

  /* Hide when not in focus */
  position: absolute;
  top: -10rem;
}

.header__nav a[href="#main"]:focus {
  /* Show when in focus */
  position: relative;
  top: 0;
}

.header__nav a[href="#main"]:focus + .header__nav__menu-button {
  /* Hide .header__nav__menu-button when in focus */
  position: absolute;
  top: -10rem;
}

.header__nav__menu-button.button {
  /* Override default button styling */
  padding-left: 0;
  padding-right: 0;
}

[aria-expanded="false"] .header__nav__menu-button__close {
  /* Hide the close icon when the menu is closed */
  display: none;
}

[aria-expanded="true"] .header__nav__menu-button__open {
  /* Hide the open icon when the menu is open */
  display: none;
}

.header__nav__menu {
  /* Hidden by default */
  display: none;
  z-index: -1;

  /* Stretched over entire screen */
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100vh;

  /* General styling */
  background-color: var(--light-grey);
  color: var(--black);
  font-size: 1rem;
}

.header__nav__menu__content {
  margin-top: var(--s2);
  margin-bottom: var(--s2);
}

[aria-expanded="true"] + .header__nav__menu {
  /* Unhidden */
  display: initial;
  z-index: 999;
}

@media (max-width: 60rem) {
  .header__nav__menu-button span:not(.screen-reader-text) {
    /* Hide the button text that isn't specifically for screen readers ("Sections" */
    display: none;
  }

  .header__nav__menu-button {
    /* Make the button bigger */
    font-size: 1.5rem;
  }
}

/*******************************************************************************
 *  Header Logo
 *******************************************************************************/

.header__logo__link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo {
  /* Scales down the logo to fit in the remaining header space on smaller screens */
  max-width: 100%;

  /* The SVG must have a height set, so here is a sensible default that can be overridden per site */
  height: 2rem;

  /* Preflight gives img "object-fit: cover" by default, but we want the logo completely visible at all times */
  object-fit: contain;
}

/*******************************************************************************
 *  Header User Controls
 *******************************************************************************/

.header__user-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/********** Menu Button **********/

.header__user-controls__menu-button {
  /* Hidden on larger screens when the menu attribute is not "true" */
  display: none;

  /* General styling */
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;

  /* Remove button styling */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}

[data-menu="true"] .header__user-controls__menu-button {
  /* Unhide the button */
  display: inline-flex;
}

@media (max-width: 60rem) {
  .header__user-controls__menu-button {
    /* Unhide the button */
    display: inline-flex;
  }
}

[aria-expanded="false"] .header__user-controls__menu-button__close {
  /* Hide the close icon when the menu is closed */
  display: none;
}

[aria-expanded="true"] .header__user-controls__menu-button__open {
  /* Hide the open icon when the menu is open */
  display: none;
}

/********** Menu **********/

.header__user-controls__menu {
  background-color: var(--black);
  color: var(--white);
}

[data-menu="true"] .header__user-controls__menu {
  /* Hidden by default */
  display: none;
  z-index: -1;

  /* Stretched over entire screen */
  position: absolute;
  left: 0;
  right: 0;
  min-height: 100vh;
}

@media (max-width: 60rem) {
  .header__user-controls__menu {
    /* Hidden by default */
    display: none;
    z-index: -1;

    /* Stretched over entire screen */
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;
  }
}

[aria-expanded="true"] + .header__user-controls__menu {
  /* Unhidden */
  z-index: 999;

  /* Flexbox to make the menu appear as a column */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* Ensure content does not touch left and right screen edges */
  padding: 0 var(--s0);
  overflow: hidden;
}

/********** Menu Content **********/

.header__user-controls__menu__content {
  display: flex;
  gap: var(--s0);
  justify-content: flex-end;
}

[data-menu="true"] .header__user-controls__menu__content {
  /* Flex */
  flex-direction: column;
  align-items: stretch;

  /* Box sizing */
  max-width: var(--content-max-width);
  margin: var(--s2) auto;
}

[data-menu="true"] .header__user-controls__menu__content .button {
  /* Full width (by replacing default inline-flex) */
  display: flex;

  /* Aesthetics */
  min-width: 15rem;
}

@media (max-width: 60rem) {
  .header__user-controls__menu__content {
    /* Flex */
    flex-direction: column;
    align-items: stretch;

    /* Box sizing */
    max-width: var(--content-max-width);
    margin: var(--s2) auto;
  }

  .header__user-controls__menu__content .button {
    /* Full width (by replacing default inline-flex) */
    display: flex;

    /* Aesthetics */
    min-width: 15rem;
  }
}

/********** Buttons **********/

.header__user-controls__menu__button {
  border: 1px solid var(--white);
}

.header__user-controls__menu__button--subscribe {
  color: var(--black);
  border: 1px solid var(--yellow);
  background-color: var(--yellow);
}
