/*
 *  Global Nav
 */

.global-nav {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap: var(--s2);
}

.global-nav__section > * + * {
  /* Puts space and a line between header text and list */
  margin-top: var(--s0);
  border-top: solid 1px var(--dark-grey);
  padding-top: var(--s0);
}

.global-nav__section__header {
  font-size: var(--text-lg);
  font-weight: bold;
}

.global-nav__section__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc((17rem / 2) - var(--s0)), 1fr));
  gap: var(--s-2) var(--s0);
}

.global-nav__section__list a:hover {
  /* Add back the link underline */
  text-decoration: revert;
}
