:root {
  /* Modular scale (see https://every-layout.dev/rudiments/modular-scale/) */
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  /* Spacing */
  --viewport-horizontal-space: var(--s0);
  --content-vertical-space: var(--s0);

  /* Lengths */
  --content-max-width: 75rem;

  /* Colors */
  --black: #333;
  --white: #fff;
  --red: #c80000;
  --light-grey: #f6f6f6;
  --grey: #ececec;
  --dark-grey: #cacaca;
  --yellow: #fbd234;
  --transparent: rgba(0, 0, 0, 0);

  /* Font Sizes */
  --text-xs: 0.65rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-lg: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-4xl: 2.5rem;
}
