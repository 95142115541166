blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*ul[role='list'],
ol[role='list'] {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}*/

input {
  font-size: 1.1rem;
  padding: var(--s-3) var(--s-2);
  border: 1px solid var(--black);
}