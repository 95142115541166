/*
The wrapper class is used to wrap full-width children.
- Prevents children from touching viewport sides (1)
- Provides default vertical margins for children (4)
- Keeps child vertical margins within content-box (2)
- Prevents children from expanding beyond a reasonable reading width (3)
- Centers the content when the viewport width exceeds reasonable reading width (4)
*/

.wrapper {
  padding: 0 var(--viewport-horizontal-space); /*1*/
  overflow: hidden; /*2*/
}

.wrapper > * {
  max-width: var(--content-max-width); /*3*/
  /* margin: var(--content-vertical-space) auto; */ /*4*/
  margin-left: auto;
  margin-right: auto;
}
