/*
 *  Article
 *
 *  URL: /news/<year>/<month>/<day>/<slug>/
 *  Template: news/story_detail
 */

h1.main__title {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -1.3px;
  word-spacing: 0.55px;
  font-weight: 700;
}

h1.main__title.font-loaded {
  font-family: "Lato Bold", sans-serif;
}

p.main__subtitle {
  font-family: Lato, Lato-Fallback-Arial, sans-serif;
  /*letter-spacing: -0.25px;
  word-spacing: 0.15px;*/
}

figure.article__lead-photo figcaption {
  font-family: Lato-Fallback-Arial, sans-serif;
  letter-spacing: 0.65px;
  word-spacing: -0.15px;
}

figure.article__lead-photo figcaption.font-loaded {
  font-family: Lato;
}




.article img {
  max-inline-size: 100%;
  block-size: auto;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.article > * + * {
  margin-top: var(--s2);
}

.article__lead-photo {
  font-size: var(--text-sm);
}

.article__lead-photo > * + * {
  margin-top: var(--s-1);
}

.article__byline a {
  text-decoration: underline;
}

/*.article__lead-photo {
  gap: var(--s-1);
}

.article__lead-photo figcaption {
  font-size: 0.9rem;
  color: #777777
}*/

.article__body {
  /*font-family: "PT Serif", serif;*/
  font-size: var(--text-xl);
  /*line-height: 1.65em;*/
  font-family: "PT-Serif-Fallback-Times-New-Roman";
}

.article__body.font-loaded, .article__body p.font-loaded {
  font-family: 'PT Serif'
}

.article__body p {
  font-family: "PT-Serif-Fallback-Times-New-Roman";
  letter-spacing: 1px;
  word-spacing: -0.6px;
  /*letter-spacing: 0.85px;*/
  /*word-spacing: 1.85px;*/
}

/*.article__body > * + *, .article__body > story > * + * {
  margin-top: var(--s0);
}*/

.article__body * + p {
  margin-top: var(--s0);
}

.article__body a {
  text-decoration: underline;
  text-underline-offset: 0.15em;
}

.article__body ol,
.article__body ul {
  list-style: initial;
  padding-left: 1.2em;
}

.article__body blockquote {
  font-family: Lato-Bold-Fallback-Arial-Bold, sans-serif;
  font-weight: bold;
  font-size: var(--text-xl);
}

.article__body.font-loaded blockquote {
  font-family: Lato-Bold;
}

/*.article__inline, .inline__block {
  padding: var(--s1);
  font-family: "Lato", sans-serif;
  font-size: initial;
  line-height: initial;
  background-color: var(--light-grey);
}

* + .article__inline,
.article__inline + * {
  margin-top: var(--s2);
}*/
